<script setup lang="ts">
import { Fragment } from 'vue'
import { useRouteQuery } from '@shared/composables/useRouteQuery'
import { isRouteActive, resolveTooltip } from './utils'
import type { ButtonGroupProps, ButtonNode, TooltipBase } from './types'
import { isSamePath } from 'ufo'
import { tv } from 'tailwind-variants'

// PROPS
const props = withDefaults(defineProps<ButtonGroupProps>(), {
  modelValue: undefined,
  persist: false,
  disabled: false,
  tooltip: undefined,
  shape: 'rounded',
})

// EMITS
const emit =
  defineEmits<(e: 'update:modelValue', value: string | number) => void>()

// GLOBAL
const route = useRoute()

// BUTTONS
const slots = useSlots()
const buttons = computed(() => {
  const defaultSlot = slots.default?.() ?? []
  const finalSlot: ButtonNode[] = []

  for (const vNode of defaultSlot) {
    if (vNode.type === Fragment) {
      finalSlot.push(...((vNode.children ?? []) as ButtonNode[]))
    } else {
      finalSlot.push(vNode as ButtonNode)
    }
  }

  return finalSlot
})
const buttonKeys = computed(() =>
  buttons.value.map((button, idx) => button.props?.name ?? idx),
)

// ACTIVE
const active = useVModel(props, 'modelValue', emit, {
  passive: true,
})

const onClick = (index: number) => {
  const button = buttons.value[index]
  if (!button.props?.to) {
    active.value = button.props?.name ?? index
  }
}

// Watch for changes in buttons and route.
// Set the active value to the first button if there is no active button.
watch(
  () => [buttons, route.path],
  () => {
    const activeByRoute = buttons.value.findIndex((button) => {
      const to = isObject(button.props?.to)
        ? button.props.to.path
        : button.props?.to
      if (typeof to !== 'string') return false
      return button.props?.exact
        ? isSamePath(to, route.path)
        : isRouteActive(to, route.path)
    })

    if (activeByRoute !== -1) {
      const key = buttonKeys.value[activeByRoute]

      if (active.value !== key) {
        active.value = key
      }

      if (props.persist) {
        console.warn("ButtonGroup: Persist feature can't be used with links")
      }
    } else if (active.value === undefined) {
      active.value = buttonKeys.value[0]
    }
  },
  { immediate: true, deep: true },
)

// PERSIST
if (props.persist) {
  const query = useRouteQuery<string | number | undefined>(
    typeof props.persist === 'string' ? props.persist : 'tab',
    () => active.value,
  )
  syncRef(query, active)
}

// VARIANT
const buttonGroup = tv({
  base: 'inline-flex gap-x-1',
  variants: {
    shape: {
      rounded: 'rounded-2xl bg-gray-900 p-1',
      square: undefined,
    },
  },
})
</script>

<template>
  <div :class="buttonGroup({ shape })" role="group">
    <component
      :is="button"
      v-for="(button, idx) in buttons"
      :key="button.key"
      :tooltip="resolveTooltip(tooltip, button.props?.tooltip)"
      :disabled="button.props?.disabled || disabled"
      :data-active="active === buttonKeys[idx]"
      :shape="shape"
      @click="onClick(idx)"
    />
  </div>
</template>
